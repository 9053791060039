<template>
<div>
    <v-card class="py-4 px-6">
        <v-card-title>
            <h3 class="mb-4">Crear Noticia</h3>
        </v-card-title>
        <v-form ref="form">
            <v-row class="px-4 pb-8">
            <v-col cols="6 mb-0">
                <v-text-field
                    label="Título de la notica"
                    placeholder="Ingresar Título"
                    outlined
                    v-model="dataTemplate.title"
                    :rules="titleRules"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="6 mb-0">
                <v-file-input
                    label="Imagen de la notica"
                    outlined
                    prepend-icon="mdi-camera"
                    v-model="dataTemplate.thumbnail"
                    :rules="fileRules"
                    @change="onAboutImage"
                    required
                ></v-file-input>
                
            </v-col>
            <v-col cols="12">
                <quill-editor
                    ref="myQuillEditor"
                    v-model="dataTemplate.description"
                    :options="editorOption"
                    required
                />
            </v-col>
        </v-row>
        <img 
            v-if="dataTemplate.thumbnail"
            :src="dataTemplate.thumbnail"
            style="max-width: 250px; max-height: 150px"
            aspect-ratio="1.7"
            contain
            class="ml-4 rounded-lg"
        >
        <v-card-actions class="mt-6">
            <v-btn
                color="primary"
                depressed
                dark
                large
                class="px-5 ml-2"
                @click="saveArticle()"
                :disabled="!valid"
            >
                {{ editMode ? 'Editar Noticia' : 'Publicar Noticia'  }}
            </v-btn>
            <v-btn
                depressed
                large
                @click="$router.push({ name: 'News' })"
                class="px-5 ml-4"
            >
                Volver
            </v-btn>
        </v-card-actions>
        </v-form>
        
        
    </v-card>
</div>    
    
</template>

<script>
import {misMixins} from '../../../vue/mixinsAlert';
import { required } from 'vuelidate/lib/validators';

export default {
    data(){
        return{
            valid: true,
            id : this.$route.params.id,
            dataTemplate: {
                title: '',
                thumbnail: '',
                description: '',
                publication_date: ''
            },
            editorOption: {
              placeholder: 'Ingrese una descripción...',
            },
            editMode: false,
            titleRules: [
                v => !!v || 'Debe ingresar un nombre',
            ],
            fileRules: [
                v => !!v || 'Debe adjuntar un archivo',
            ],
        }
    },
    mixins:[misMixins], 
    methods: {
        getArticle(){
            if(this.id){
                this.editMode = true;
                this.$http.get('articles/'+this.id)
                .then(response => {

                    const data = response.body;
                    this.dataTemplate.title =  data.title;
                    this.dataTemplate.description =  data.description;
                    this.dataTemplate.thumbnail =  data.thumbnail;

                }).catch(error => {
                    console.log('Error al consultar la noticia', error)
                })

                this.loader = false;
            }
        },
        saveArticle(){
            const attributes = {
                title: this.dataTemplate.title,
                description: this.dataTemplate.description,
                thumbnail: this.dataTemplate.thumbnail,
                publication_date: new Date(),
                townhall_id: this.getClientSelect().id
            }

            if(this.$refs.form.validate() && this.dataTemplate.description !== ''){
                this.LoadingOpen();
                if (!this.editMode){

                    this.$http.post('articles',{
                        data: {
                            attributes
                        }
                    },
                    {
                        headers:{
                            Authorization: 'Bearer '+this.getCrypto()
                        }
                    }).then(response => {
                        console.log('Save SubCategory:', response)
                        this.LoadingClose();

                        this.SucessAlert(
                            'Creada',
                            'Noticia creada con éxito'
                        )
                        this.resetForm();
                    }).catch(error => {
                        this.LoadingClose();
                        console.log('Error al guardar noticia', error)
                        this.ErrorAlert('Error al crear la noticia')
                    })
                } else {
                    this.$http.put('articles/'+this.id, {
                        data: {
                            attributes
                        }
                    },
                    {
                        headers:{
                            Authorization: 'Bearer '+this.getCrypto()
                        }
                    }).then(response => {
                        console.log('Edit SubCategory:', response)
                        this.LoadingClose();

                        this.SucessAlert(
                            'Actualizada',
                            'Noticia actualizada con éxito'
                        )
                    }).catch(error => {
                        this.LoadingClose();
                        this.ErrorAlert('Error al actualizar la noticia')
                        console.log('Error al editar Noticia', error)
                    })
                }
                
            }
            
        },
        resetForm(){
            this.editMode = false
            this.dataTemplate = {
                title: '',
                thumbnail: '',
                description: '',
                publication_date: ''
            }
            this.id = '';
        },
        onAboutImage(imagen){
            var reader = new FileReader();
            var vm = this;
            reader.onload = function () {
                vm.dataTemplate.thumbnail = reader.result
            }
            reader.readAsDataURL(imagen);
        },
    },
    created(){
        this.getArticle()
    }
}
</script>